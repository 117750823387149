<template>
    <div>
        <div class="section border-top padding-top-45 padding-bottom-45">
            <!-- Logo Carousel -->
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <!-- Section Headline -->
                        <Headline :title="head.title"> </Headline>
                    </div>
                    <div class="col-xl-12">
                        <!-- Carousel -->
                        <div class="col-md-12">
                            <div class="logo-carousel freelancers-container freelancers-grid-layout">
                                <div v-for="(item, i) in companies"
                                    class="carousel-item slick-slide slick-current slick-active" :data-slick-index="i"
                                    aria-hidden="true" style="width: 230px;" tabindex="-1">
                                    <a :href="item.link" target="_blank" tabindex="-1"><img
                                            :src="`images/companies-logo/${item.logo}`" alt=""></a>
                                </div>

                            </div>
                        </div>
                        <!-- Carousel / End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Headline from './Headline.vue';
import companiesData from '../../content/home/companies.json'; // Adjust the path based on your project structure

export default {
    components: {
        Headline
    },
    mounted() {
        if (process.browser) {
            initialCarousel();
        }
    },
    data() {
        return {
            head: { title: companiesData.title },
            companies: companiesData.companies
        };
    },
}
</script>

<style lang="scss" scoped></style>